.pageSelector {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
}

.pageSelector button {
  min-width: auto;
}

.pageSelector button:disabled {
  background-color: #f0f0f0;
  color: #a0a0a0;
  cursor: not-allowed;
}