.infoAndPhotosContainer{
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 50px;
}

.statusContainer {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.statusIndicator {
    font-size: 15px;
    font-weight: 700;
    padding: 10px;
    border-radius: 5px;
    width: 25%;
    min-width: 100px;
    text-align: center;
}

.statusIndicator.green {
    background-color: #d4f4cd;
    color: #133213;
}

.statusIndicator.red {
    background-color: #f4d4d4;
    color: #331313;
}

.statusIndicator.blue {
    background-color: #d4e4f4;
    color: #132133;
}

.infoContainer{
    flex: 2;
    text-align: justify;
    max-width: 1000px;
}

.photosContainer{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.images {
    width: 100%;
    margin: 20px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.tableContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}


@media  (max-width: 767px) {
    .infoAndPhotosContainer {
        flex-direction: column;
    }
    .buttonContainer {
        flex-direction: column;
    }
    .statusIndicator {
        width: 50%;
    }
}

@media print {
    /*reduce the font size*/

    .mainContainer {
        display: flex;
        gap: 1rem;
        font-size: 10px;
    }

    thead {
        border: 1px solid #000;
    }

    td, th {
        padding: 0.5rem;
        border: 1px solid #000;
    }

    .date {
        font-size: 10px;
    }

    .photosContainer {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
    }
    img {
        width: 10%;
        margin: 5px;
    }

    .buttonContainer {
        display: none;
    }

}


