.avgsComponentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  background-color: #f5f5f5;
  border-radius: 40px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
}

.avgsComponentContainer p {
  flex: 1;
  font-weight: 600;
  padding: 10px;
}

@media (max-width: 767px) {
  .avgsComponentContainer {
    width: 95%;
  }
}