.vehiclesManagement .vehicleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 700px;
    background-color: #f5f5f5;
    border-radius: 40px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
    padding: 20px;
    gap: 20px;
}

.vehiclesManagement .vehicleContainer .iconsContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.vehiclesManagement .vehicleContainer .infoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: normal;
    width: 100%;
    gap: 10px;
}

@media (max-width: 767px){
    .vehiclesManagement .vehicleContainer {
        flex-direction: column;
        gap: 20px;
    }
}
