.imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 50px;
}

.image {
    width: 200px;
    height: 200px;
    object-fit: cover;
}