.onCallExchange {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 40px;
    background-color: #f5f5f5;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
    width: 90%;
    max-width: 600px;
    gap: 50px;
}

.infoAndPhotosContainer .notificationContainer {
    flex: 1;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}