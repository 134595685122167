.mainLoginContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    overflow: hidden;
}

.loginImageContainer {
    flex: 2;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
}

.loginFormContainer {
    flex: 1;
    width: 100%;
    height: 100vh;
    padding: 50px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.loginH1 {
    font-family: "JetBrains Mono", serif;
    font-size: 2.5rem;
    font-weight: bold;
    color: #257cff;
}

.customFooter {
    position: absolute;
    bottom: 0;

    font-size: 10px;
    color: #7c7c7c;
}

@media screen and (max-width: 767px) {
    .loginImageContainer {
        flex: 0;
    }
}