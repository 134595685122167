.settings .vehiclesContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 700px;
    background-color: #f5f5f5;
    border-radius: 40px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
    padding: 20px;
}

.settings .listContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 25px;
}

.settings .vehiclesContainer .vehiclesInfo {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
}

.settings .footer {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 767px) {
    .settings .vehiclesContainer {
        flex-direction: column;
        gap: 20px;
    }

    .settings .vehiclesContainer .vehiclesInfo {
        flex: 1;
    }
    .settings .footer {
        flex-direction: column;
    }
}