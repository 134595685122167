:root {
  --color-primary: #257cff;
  --color-secondary: #69a2f8;
  --color-text: #121212;

}

.mainContainer {
  margin: 5%;
  width: 100%;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 75px;
}

.App {
  text-align: center;
  background-color:#ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

}


h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 30px;
  font-weight: 700;
  color: var(--color-text);
}


.button {
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
  font-weight: 700;
  color: #ffffff;
  background-color: var(--color-primary);
  border: none;
  border-radius: 10px;
  padding: 15px 20px;
  cursor: pointer;
  /*transition: all 0.3s ease-in-out; */
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  text-decoration: none;
  min-width: 300px;

  /* Show element centred inside */
  display: flex;
  justify-content: center; /* horizontal alignment */
  align-items: center; /* vertical alignment */
}

.button.delete {
  background-color: #DC143CFF;
}

.button:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
}


table {
  border-collapse: collapse;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  min-width: 250px;
}

table tbody tr:hover {
  background-color: rgb(248, 248, 248);
  box-shadow: 0 0 10px rgba(220, 220, 220, 0.85);
  transition: 0.2s;
  transform: scale(1.01);
}

table tbody tr {
  transition: 0.2s;
}

thead {
  background-color: var(--color-primary);
  color: #ffffff;
  /* make thead sticky */
  position: sticky;
  top: 0;
}

tbody {
  background-color: #ffffff;
  color: var(--color-text);
}

td {
  text-align: center;
  padding-inline: 20px;
  border-bottom: 1px solid #ddd;
  padding: 20px 8px;
  /* wrap text */
  max-width: 250px;
}

th {
  text-align: center;
  padding: 8px;
}

.table_elements {
  padding: 5px;
  border-radius: 5px;
  background-color: #ebf1fd;
  font-weight: 600;
  margin: 10px;
}


.date {
  font-size: 15px;
  font-weight: 700;
  color: var(--color-text);
  padding: 10px;
  border-radius: 5px;
  background-color: #ebeefd;
}


.form__group {
  position: relative;
  padding: 20px 0 0;
  width: 100%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: none;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  font-size: 18px;
  color: var(--color-text);
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
  margin: auto;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 17px;
  cursor: text;
  top: 20px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 17px;
  color: #9b9b9b;
  pointer-events: none;
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: normal;
  border-width: 3px;
  border-image: linear-gradient(to right, var(--color-primary), var(--color-secondary));
    border-image-slice: 1;
}

.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 17px;
  color: var(--color-primary);
  font-weight: 700;
}

/* reset input */
.form__field:required, .form__field:invalid {
  box-shadow: none;
}

footer{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center; /* horizontal alignment */
  align-items: center; /* vertical alignment */
  font-size: 15px;
  font-weight: 700;
}

@media (max-width: 767px) {
  footer {
    flex-direction: column;
  }
}

/*Rimuove quelle rudo di freccette su input number*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Permette di ridimensionare la textarea in safari */
textarea {
  -webkit-appearance: textfield;
}