
.home .datePickerContainer {

  /* Position of the element */
  margin: 0 auto 0 auto;
    width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 20px;  /* The 20px up are defined in input class */

  /* Color of the element */
  color: white;
  background-color: #257CFFFF;
  border-radius: 40px;
  max-width: 300px;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.home .datePickerContainer input{

    /* Position of the element */
    width: 60%;
    height: 30px;

    /* Color of the element */
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 5px;
    margin: 5px;
    background-color: #257CFFFF;
    color: white;
    font-size: 17px;
    outline: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  /* Added shadow for depth */
    transition: border-color 0.3s, box-shadow 0.3s;
}

.home .datePickerContainer input:hover {
    transform: scale(1.01);
    transition: transform 0.3s;
    border: 1px solid #efefef;
}

.home .datePickerContainer input {
    transition: transform 0.3s;
}

.processed {
    background-color: #f0f6ff;
}