.errorMessageContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: #DC143CFF;
    border-radius: 30px;
    padding: 30px;

    color: #ebeefd;
}

@media (max-width: 767px) {
    .errorMessageContainer {
        width: 60%;
    }
}