.searchBoxContainer {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    /* Color of the element */
    color: white;
    background-color: #257CFFFF;
    border-radius: 40px;
    width: 100%;
    font-weight: bold;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
}

.searchBoxRow {
    /* Position of the element */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px 20px 20px;  /* The 20px up are defined in input class */
    gap: 50px;
}

.searchBoxContainer .elementFilter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.searchBoxContainer input{
    width: 100%;
    height: 30px;
    border-radius: 10px;
    border: 1px solid #ffffff;
    padding: 5px;
    margin: 5px;
    background-color: transparent;
    color: white;
    font-size: 18px;
}

.searchBoxContainer input:focus {
    outline: 2px solid #ffffff;
    transition: outline 0.1s ease-in-out;
}

.searchBoxContainer select {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    border: 1px solid #ffffff;
    padding: 5px;
    margin: 5px;
    background-color: transparent;
    color: white;
    font-size: 18px;
}

.searchBoxContainer select:focus {
    outline: 2px solid #ffffff;
    transition: outline 0.1s ease-in-out;
}

.searchBoxContainer .searchButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;  /* Transparent background */
    border-radius: 10px;
    padding: 5px;
    border: 1px solid #ffffff;
    outline: none;   /* Remove the border when the button is clicked */
    color: white;
    font-size: 30px;
    cursor: pointer;
}

.searchBoxContainer .searchButton:hover {
    background-color: #ffffff;
    color: #257CFFFF;
    transition: background-color 0.1s ease-in-out;
}

@media (max-width: 767px) {
    .searchBoxContainer {
        flex-direction: column;
        gap: 0;
        width: 100%;
    }

    .searchBoxContainer .searchBoxRow {
        flex-direction: column;
        gap: 0;
        width: 100%;
    }
}
